export const formBaseUrl = 'https://www.tfaforms.com/4971036'
const clusterList = {
	Mumbai: 'tfa_27424',
	Delhi: 'tfa_27915',
	Bengaluru: 'tfa_27947',
	Ahmedabad: 'tfa_27965',
	Chennai: 'tfa_27999',
	Hyderabad: 'tfa_28011',
	Pune: 'tfa_28021',
}

// const availabilityMap = {
// 	'Weekday - morning (7 a.m. - 12 p.m.)': 'tfa_306',
// 	// Flexible: 'tfa_310',
// 	'Weekday - late evening (6 p.m. - 9 p.m.)': 'tfa_309',
// 	'Weekday - afternoon (12 p.m. - 3 p.m.)': 'tfa_307',
// 	'Weekday - early evening (3 p.m. - 6 p.m.)': 'tfa_308',
// }

const availabilityList = {
	'Weekday (Anytime between 7:00 AM - 12.00 PM)': 'tfa_32716',
	'Weekday (Anytime between 12:00 PM - 3:00 PM)': 'tfa_32717',
	'Weekday (Anytime between 3:00 PM - 6:00 PM)': 'tfa_32718',
	'Weekday (Anytime between 6:00 PM - 9:00 PM)': 'tfa_32719',
	'Weekend (Saturdays - for a few hours)': 'tfa_32720',
}

const keyMapping = {
	mode: 'tfa_27404',
	city: 'tfa_27407',
	subject1: 'tfa_290',
	subject2: 'tfa_27833',
	ref_code: 'tfa_28800',
	availability: 'tfa_305',
	cluster: 'tfa_002',
}

/**
 * Generates a parameterised FormAssembly URL
 * @param {object} params the parameters to pass in the URL
 * @param {string} params.mode
 * @param {string} params.city
 * @param {string} params.subject1
 * @param {string} params.subject2
 * @param {string} params.ref_code
 * @param {string} params.availability
 * @param {string} params.cluster
 * @returns {string} Parameterised FormAssembly URL
 */
export default function getFormUrl(params) {
	let formUrl = `${formBaseUrl}`
	let validParams = Object.entries(params).filter(([key, value]) => {
		if (value && keyMapping[key]) {
			return true
		} else {
			return false
		}
	})
	let cityVal, availabilityVal
	if (validParams.length > 1) {
		formUrl += '?'
		validParams.forEach(([key, value], index) => {
			if (index !== 0) {
				formUrl += '&'
			}
			if (key === 'city') cityVal = value

			if (key === 'cluster') {
				key = clusterList[cityVal]
				formUrl += `${key}=${value}`
				return
			}

			if (key === 'availability') {
				availabilityVal = value
				availabilityVal.forEach((item, index) => {
					key = availabilityList[availabilityVal[index]?.trim()]
					if (index !== 0) {
						formUrl += '&'
					}
					formUrl += `${key}=${item}`
				})
				return
			}
			formUrl += `${keyMapping[key]}=${value}`
		})
	}
	return formUrl
}
